<template>
  <div class="CaseHome">
    <div class="caseData">
      <div class="AppData">
        <span>总数</span>
        <div class="CentralData">
          <span>{{ caseData.countSum}}</span>
        </div>
        <div>
          <span>总</span>
        </div>
      </div>
      <div class="AppData">
        <span>待审批</span>
        <div class="CentralData">
          <span>{{caseData.countAcc}}</span>
        </div>
        <div >
          <span>总</span>
        </div>
      </div>
      <div class="AppData">
        <span>待打印</span>
        <div class="CentralData">
          <span>{{ caseData.countPrint }}</span>
        </div>
        <div >
          <span>总</span>
        </div>
      </div>
      <div class="AppData">
        <span>待结算</span>
        <div class="CentralData">
          <span>{{caseData.countAudit}}</span>
        </div>
        <div >
          <span>总</span>
        </div>
      </div>
    </div>
    <div class="bar">
      <div style="display: flex;justify-content: space-between" >
        <span style="font-size: 18px;font-weight: bold;padding: 2px;">病例统计</span>
        <div>
          <a-select
              style="width:240px;margin-right:5px;height: 20px"
              placeholder="选择病例模板"
              @change="handleChange"
              mode="multiple"
              :maxTagCount= 1
              showArrow
              :maxTagTextLength = 7
          >
            <a-select-option v-for="item in caseTitle" :key="item.title" :value="item.title">
              {{item.title}}
            </a-select-option>
          </a-select>
          <a-button type="primary" @click="search" >查询</a-button>
        </div>
      </div>
      <a-divider />
      <div id="barData"  style="width: 98%;height: 400px;"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import {getCaseData, getCaseTitle} from "@/service/CaseTemplate_api";

export default {
  name: "CaseHome",
  data(){
    return{
      cases:'',
      caseData: {},
      caseTitle:[],
      caseBar:[
        {
          title:'',
          countTemplate:''
        }
      ]
    }
  },
   created() {
    this.$store.dispatch('setManageHeaderTitle',"病例首页")

  },
  async mounted() {
    await this.getCases()
    await this.getCaseHome()
    this.myChart()
  },
  methods:{
    //获取病例数据
    async getCases(title){
      const cases = await getCaseData(title)
      this.caseData =cases.data
      let newArr = [];
      this.caseBar = newArr
      cases.data.rows.map(function(item,index){
        let sev = {};
        sev['title'] = item.title;
        sev['count'] = item.countTemplate;
        newArr[index] = sev
      })
    },
    async getCaseHome (){
      const value = await getCaseTitle()
      this.caseTitle = value.data.rows
    },
     handleChange(value) {
       this.caseValue = value
    },
    //搜索
    async search(){
      const title = this.caseValue.toString();
      await this.getCases(title)
      this.myChart()
    },
    //病例柱状图
    myChart(){
      const barData = echarts.init(document.getElementById('barData'));
      let Case = {
        dataset:[
          {source: this.caseBar},
        ],
        title: [
          {text: '病历趋势'}
        ],
        grid: {
          left: '-100',
          right: '150',
          bottom: '3%',
          top:'62',
          containLabel: true
        },

        xAxis: {
          type: 'value'
        },
        yAxis: [{
          nameTextStyle:{
            fontSize:14
          },
          axisLabel:{
            interval:0,
            margin:200,
            textStyle: {
              align:'left',
              baseline:'middle'
            },
            formatter: function (value) {
              if (value.length > 15) {
                return `${value.slice(0, 14)}...`
              }
              return value
            },
          },

          type: 'category',
        }],
        series: [{type: 'bar'}]
      };
      barData.setOption(Case);
    }
  },
}
</script>

<style lang="scss" scoped>
.CaseHome{
  margin: -15px -15px;
  background-color:  #f1f2f6;
  .caseData{
    display: flex;
    justify-content: space-between;
    margin: 0 auto 10px;
    .AppData{
      width: 24%;
      background-color: white;
      padding: 10px 20px;
      .CentralData{
        font-size: 30px;
        padding: 10px 0 10px 0;
        font-weight: bold;
      }
    }
  }
  .bar{
    padding: 10px;
    background-color: white;
    .ant-divider-horizontal{
      margin: 12px 0 !important;
    }
  }
}
</style>